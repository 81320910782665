import dynamic from "next/dynamic";

const DynamicHomeTenant = dynamic(() =>
  import("../modules/Tenant/index").then((mod) => mod.HomeTenant)
);

const Home: React.FC = () => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://unpkg.com/swiper@7/swiper-bundle.min.css"
      />
      <DynamicHomeTenant />
    </>
  );
};

export default Home;
